import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: "5b2a8dc324476fa07151f0d8e8edf8868e286ec2",
  tracesSampleRate: 0,
  integrations: [new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});